// import downloadBackground from "../assets/img/logodownloadbackground.png"
import downloadApple from "../assets/img/downloadappstore.png"
import downloadAndroid from "../assets/img/downloadgooglestore.png"
import iPhone from "../assets/img/iPhone 15.png"
// import getInvitedArrow from "../assets/img/getinvitedarrow.png"

// import { ScaleText } from "react-scale-text"

export const Download = () => {
    return(
        <div id="download" className="download-background">
            <div className="download-container">
                <div className="download-text">
                <h1 style={{ fontFamily: 'SIFONN' }}>DOWNLOAD</h1>
                <h2 style={{ fontFamily: 'SIFONN' }}>99 YARDS</h2>
                <p style={{ fontFamily: 'Inter-Regular', lineHeight: '1.5' }}>
    A comprehensive digital platform for booking and hiring fashion talent directly, simplifying the process with an easy-to-use interface and a diverse pool of professionals. Ideal for accessing top talent in modeling, photography, styling, and more, all in one place.
</p>                    <a className="button-link" href="https://sf2osh4qouo.typeform.com/to/xENd3ZY5?typeform-source=localhost">
                        {/* <button className="join-waitlist-button">
                            Join Waitlist
                            <img className="get-invited-arrow" src={getInvitedArrow} alt="Get Invited" />
                        </button> */}
                    </a>
                    <div className="download-images" style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
    <a href="...">
        <img className="download-image-apple" src={downloadApple} alt="Download from App Store" />
    </a>
    <a href="..." style={{ marginLeft: '1rem' }}>
        <img className="download-image-android" src={downloadAndroid} alt="Download from Google Play Store" />
    </a>
   </div>
                </div>
                <div className="iphone-container">
                    <img className="iphone-image" src={iPhone} alt="iPhone" />
                </div>
                <div className="download-images-mobile">
                    <a href="https://apps.apple.com/us/app/99-yards/id6499562420" target="_blank" rel="noopener noreferrer">
                        <img className="download-image-apple" src={downloadApple} alt="Download from App Store" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=org.ninety.nineyards.app" target="_blank" rel="noopener noreferrer">
                        <img className="download-image-android" src={downloadAndroid} alt="Download from Google Play Store" />
                    </a>
                </div>
            </div>
        </div>
    )
}